/** @jsx jsx */
import * as React from 'react';
import { jsx, Heading, Text, Box } from 'theme-ui';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Layout from './layout';
import ItemTags from './item-tags';
import Seo from './seo';
import PostFooter from './post-footer';
import { yearMonthDayShortFormatter } from '../../../utils/date';
import { contents } from '../styles/utils';

type PostProps = {
  data: {
    post: {
      slug: string;
      title: string;
      date: string;
      tags?: {
        name: string;
        slug: string;
      }[];
      description?: string;
      canonicalUrl?: string;
      body: string;
      excerpt: string;
      timeToRead?: number;
      banner?: {
        childImageSharp: {
          resize: {
            src: string;
          };
        };
      };
    };
  };
};

const px = [`32px`, `16px`, `8px`, `4px`];
const shadow = px.map(v => `rgba(0, 0, 0, 0.15) 0px ${v} ${v} 0px`);

const Post = ({ data: { post } }: PostProps) => (
  <Layout>
    <Seo
      title={post.title}
      description={post.description ? post.description : post.excerpt}
      image={
        post.banner ? post.banner?.childImageSharp?.resize?.src : undefined
      }
      pathname={post.slug}
      canonicalUrl={post.canonicalUrl}
    />
    <Heading as="h1" variant="styles.h2">
      {post.title}
    </Heading>
    <Text
      sx={{
        color: `secondary`,
        mt: 3,
        a: { color: `secondary` },
        fontSize: [1, 1, 2],
      }}
    >
      <time>{yearMonthDayShortFormatter(post.date)}</time>
      {post.tags && (
        <React.Fragment>
          {` — `}
          <ItemTags tags={post.tags} />
        </React.Fragment>
      )}
      {post.timeToRead && ` — `}
      {post.timeToRead && <span>{post.timeToRead} min read</span>}
    </Text>
    <section>
      <Box
        sx={{
          my: 5,
          '.gatsby-resp-image-wrapper': {
            my: [4, 4, 5],
            boxShadow: shadow.join(`, `),
          },
          variant: `layout.content`,
          ...contents,
        }}
      >
        <MDXRenderer>{post.body}</MDXRenderer>
      </Box>
    </section>
    <PostFooter post={post} />
  </Layout>
);

export default Post;
